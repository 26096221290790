<template>
  <div class="">
    <ControlNavbar/>
    <div class="container-fluid">
      <div class="banner" v-if="bill.banner">
        <Banner :banner="bill.banner"/>
      </div>
      <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
      >
        <a-form-item :label="locale.country">
          <a-select
              v-decorator="[
              'countryCode',
              {
                initialValue: bill.data.defaultCountry,
                rules: [
                  { required: true, messasge: locale.pleaseselectyourcountry },
                ],
              },
            ]"
          >
            <a-select-option
                v-for="country in bill.data.countries"
                :key="country"
                :value="country"
            >
              <!-- {{ country }} -->
              {{ countries[country.toUpperCase()] }}
              <img
                  :src="require(`../assets/flags/${country}.png`)"
                  width="35px"
                  alt=""
              />
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="locale.billType" v-if="bill.data.billTypes">
          <a-select
              @change="handleBillTypeChange"
              v-decorator="[
              'typeId',
              {
                initialValue: bill.data.defaultBillTypeId,
                rules: [{ required: true, message: locale.emptyFieldsWarning }],
              },
            ]"
          >
            <a-select-option
                v-for="type in bill.data.billTypes"
                :key="type.typeId"
                :value="type.typeId"
            >
              {{ type.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="bill.data.hint" v-if="billType === 'phone'">
          <a-input
              style="width: 100%"
              v-decorator="[
              'msisdn',
              {
                rules: [
                  { required: true, message: `${locale.emptyFieldsWarning}` },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="danger" html-type="submit">
            {{ bill.data.retrieve }}
          </a-button>
        </a-form-item>
      </a-form>
      <a-modal
          v-model="visible"
          :title="locale.intTopupLabel"
          @ok="handleOk"
          v-if="topups"
      >
        <a-collapse accordion>
          <a-collapse-panel
              v-for="(category, index) in topups.categories"
              :key="index"
              :header="`${category.name}`"
          >
            <p
                v-for="product in category.products"
                :key="product.id"
                class="product"
                @click="
                () => {
                  showPropsConfirm(product);
                }
              "
            >
              {{ product.name }} <br/>
              <small v-if="product.validityPeriod">{{
                  `${topups.labelValidity.replace(
                      "*",
                      product.validityPeriod || "-"
                  )}`
                }}</small
              ><br/>
              <small
              >{{ topups.labelCost.replace("*", product.costPerUnit) }}
                {{ product.currency }}</small
              ><br/>
              <small>
                {{ topups.labelSelling.replace("*", product.pricePerUnit) }}
                {{ product.currency }}</small
              >
            </p>
            <hr/>
          </a-collapse-panel>
        </a-collapse>

        <h4>
          <strong>{{ locale.other }}</strong>
        </h4>

        <a-collapse accordion>
          <a-collapse-panel
              v-for="(other, index) in topups.other"
              :key="index"
              :header="`${other.name}`"
          >
            <p
                v-for="product in other.products"
                :key="product.id"
                class="product"
                @click="
                () => {
                  showPropsConfirm(product);
                }
              "
            >
              {{ product.name }}<br/>
              <small v-if="product.validityPeriod">{{
                  `${topups.labelValidity.replace(
                      "*",
                      product.validityPeriod || "-"
                  )}`
                }}</small
              ><br/>
              <small
              >{{ topups.labelCost.replace("*", product.costPerUnit) }}
                {{ product.currency }}</small
              ><br/>
              <small>
                {{ topups.labelSelling.replace("*", product.pricePerUnit) }}
                {{ product.currency }}</small
              >
            </p>
          </a-collapse-panel>
        </a-collapse>
      </a-modal>
      <a-modal
          v-if="topupAmountModalVisible"
          v-model="topupAmountModalVisible"
          :title="locale.intTopupLabel"
          :ok-text="locale.proceed"
          :cancel-text="locale.cancel"
          @ok="submitTopupAmount"
      >
        <a-form-item
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 12 }"
            :label="locale.quantity"
            :help="
            topups.dialogEnterAmount
              .replace(
                '#min',
                `${topupAmountProductObject.minAmount / 10000}${
                  topupAmountProductObject.currency
                }`
              )
              .replace(
                '#max',
                `${topupAmountProductObject.maxAmount / 10000}${
                  topupAmountProductObject.currency
                }`
              )
          "
        >
          <a-input-number
              :formatter="
              (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
              :min="topupAmountProductObject.minAmount / 10000"
              :max="topupAmountProductObject.maxAmount / 10000"
              v-model="topupAmount"
          />
        </a-form-item>
      </a-modal>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import countriesCode from "../scripts/countries";
import axios from "axios";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

// import Slick from "vue-slick";

// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";

export default {
  name: "IntTopup",
  components: {ControlNavbar},
  data() {
    return {
      locale: null,
      topupAmountModalVisible: false,
      sms: false,
      price: "",
      payAllPrice: "",
      billActualPrice: null,
      payAll: false,
      billDetails: null,
      visible: false,
      topups: null,
      topupAmount: null, //used to get the amount from topup dialog if max != min amount
      topupAmountFormValues: null, //used because we are going to use a modal outside the function and we need the values inside the function
      topupAmountProductObject: null, //taking the product to be able to create the modal because th eproduct values are in the function and we need them to be global to reach the modal
      countries: countriesCode.countriesCode,
      billType: "phone",
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "billForm"}),
      modalForm: this.$form.createForm(this, {name: "billFormModal"}),
      bill: this.$route.params.object,
    };
  },
  methods: {
    openTopupAmountModal() {
      this.topupAmountModalVisible = true;
    },
    toggleTopupAmountModal() {
      this.topupAmountModalVisible = !this.topupAmountModalVisible;
    },
    submitTopupAmount() {
      if (
          this.topupAmount >= this.topupAmountProductObject.minAmount / 10000 &&
          this.topupAmount <= this.topupAmountProductObject.maxAmount / 10000
          //check if the amount is okay
      ) {
        // double cost = (product.getCostPerUnit() / 10000.0) * (amount);
        //  final double price = (product.getPricePerUnit() / 10000.0) * (amount);
        let price =
            (this.topupAmountProductObject.pricePerUnit / 10000) *
            this.topupAmount;
        let cost =
            (this.topupAmountProductObject.costPerUnit / 10000) *
            this.topupAmount;
        let dialogAmountMessage =
            this.topups.dialogAmountMessage ||
            "Topup Amount: #amount.\nCost : #cost.\nSuggested selling price: #price.";
        let currency = this.$session.get("currency");
        let productCurrency = this.topupAmountProductObject.currency;

        dialogAmountMessage = dialogAmountMessage.replace(
            "#amount",
            this.topupAmount + ` ${productCurrency}`
        );
        dialogAmountMessage = dialogAmountMessage.replace(
            "#cost",
            cost + ` ${currency}`
        );
        dialogAmountMessage = dialogAmountMessage.replace(
            "#price",
            price + ` ${currency}`
        );
        dialogAmountMessage = dialogAmountMessage.split("\n");
        this.topupAmountFormValues.amount = this.topupAmount * 10000;
        let him = this;
        let tempAxios = this.getAxios();
        let values = this.topupAmountFormValues;
        console.log("topupAmount form values ", values);
        this.$confirm({
          title: `${him.locale.topup} ${him.topupAmountFormValues.msisdn}`,
          content: () => (
              <div>
                <p>{dialogAmountMessage[0]}</p>
                <p>{dialogAmountMessage[1]}</p>
                <p>{dialogAmountMessage[2]}</p>
              </div>
          ),
          okText: him.locale.proceed,
          okType: "danger",
          cancelText: him.locale.cancel,
          onOk() {
            tempAxios
                .post(
                    `${him.$session.get("baseURI")}sale/international/topup`,
                    values
                )
                .then((result) => {
                  if (this.checkApiRes(result, values, "sale/international/topup", "post")) {
                    // ok
                    console.log("after buyng plan", result);
                    him.success(
                        result.data.dialog.title,
                        result.data.dialog.message
                    );
                    him.handleOk("e");
                    him.topupAmountModalVisible();
                  }
                });
          },
          onCancel() {
            console.log("Cancel");
          },
          class: "test",
        });

        // fix price in form values
        console.log("form amount", this.topupAmountFormValues);
        // submit the form when done finished topupAmountModalVisible to false
      }
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    smsToggled(checked) {
      this.sms = checked;
    },
    amountToggled(checked) {
      // if true pay all bill
      this.payAll = checked;
      this.price = null;
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.countryCode = this.countries[
              `${values.countryCode.toUpperCase()}`
              ];
          values.msisdn = `${values.msisdn}`;
          values.id = this.bill.data.id;
          console.log("Received values of form: ", values);
          this.getAxios()
              //if an error occurred here please replace the url with this  `${this.$session.get("baseURI")}sale/international/topup/fetch?countryCode=${values.countryCode}&&msisdn=${values.msisdn};`
              .post(`${this.$session.get("baseURI")}sale/international/topup/fetch?countryCode=${values.countryCode}&&msisdn=${values.msisdn}`,
                  ""
              )
              .then((result) => {
                if (this.checkApiRes(result, "", `sale/international/topup/fetch?countryCode=${values.countryCode}&&msisdn=${values.msisdn}`, "post")) {
                  // ok
                  console.log("after first submit", result);
                  this.topups = result.data.data;
                  this.showModal();
                }
              });
        }
      });
    },
    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
      });
    },
    handleBillTypeChange(e) {
      this.bill.data.billTypes.forEach((billType) => {
        if (billType.typeId == e) {
          this.billType = billType.inputType;
        }
      });
    },
    showPropsConfirm(product) {
      console.log("product: ", product);
      let tempAxios = this.getAxios();
      let him = this;
      if (product.minAmount === product.maxAmount) {
        this.form.validateFields((err, values) => {
          if (!err) {
            // creating body
            values.countryCode = this.countries[
                `${values.countryCode.toUpperCase()}`
                ];
            values.msisdn = `${values.msisdn}`;
            values.id = Number(this.topups.id);
            values.price =
                (product.costPerUnit / 10000) * (product.maxAmount / 10000);
            values.productId = Number(product.id);
            values.instance = this.topups.instance;
            values.amount = (product.minAmount / 10000) * 10000;
            values.pricePerUnit = product.pricePerUnit;
            values.costPerUnit = product.costPerUnit;
            console.log("Received values of form: ", values);
            let topupdialog = this.topups.dialogMessage;
            topupdialog = topupdialog.replace("#product", product.name);
            topupdialog = topupdialog.replace("#cost", values.price.toFixed(2));
            topupdialog = topupdialog.split("\n");
            // end of body
            this.$confirm({
              title: `${him.locale.topup} ${values.msisdn}`,
              content: () => (
                  <div>
                    <p>{topupdialog[0]}</p>
                    <p>
                      {topupdialog[1]}
                      {product.currency}
                    </p>
                  </div>
              ),

              okText: him.locale.proceed,
              okType: "danger",
              cancelText: him.locale.cancel,
              onOk() {
                tempAxios
                    .post(
                        `${him.$session.get("baseURI")}sale/international/topup`,
                        values
                    )
                    .then((result) => {
                      if (this.checkApiRes(result, values, "sale/international/topup", "post")) {
                        // ok
                        console.log("after buyng plan", result);
                        him.success(
                            result.data.dialog.title,
                            result.data.dialog.message
                        );
                        him.handleOk("e");
                      }
                    });
              },
              onCancel() {
                console.log("Cancel");
              },
            });
          }
        });
      } else {
        console.log("not the same");
        this.form.validateFields((err, values) => {
          if (!err) {
            // creating body
            values.countryCode = this.countries[
                `${values.countryCode.toUpperCase()}`
                ];
            values.msisdn = `${values.msisdn}`;
            values.id = Number(this.topups.id);
            values.productId = Number(product.id);
            values.instance = this.topups.instance;
            values.pricePerUnit = product.pricePerUnit;
            values.costPerUnit = product.costPerUnit;
            console.log("topup amount form values: ", values);
            this.topupAmountFormValues = values;
            this.topupAmountProductObject = product;
            // create a modal for topup amount
            // fill it from topupAmountProductObject
            // v-model amount to topupAmount
            // onOk take topupAmountFormValues add amount=topupAmount*10k
            // submit the form with body as topupAmountFormValues
            this.openTopupAmountModal();
            // end of body
          }
        });
      }
    },
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
  },
  created() {
    this.checkLocal();
    console.log(
        "bill: ",
        this.$route.params.object || this.$session.get("backup")
    );
    if (!this.$route.params.object) {
      // undefined
      this.bill = this.$session.get("backup");
    } else {
      this.bill = this.$route.params.object;
      this.$session.set("backup", this.$route.params.object);
    }
  },
};
</script>

<style lang="scss" scoped>
.product {
  border-bottom: 1px solid lightslategrey;
  cursor: pointer;
}

.billPairs {
  background-color: grey;
  border-radius: 45px;
  color: white;
  padding: 2px;
}
</style>
